import React from "react";
import styled from "styled-components";
import pic from "../Asset/11.png";

const Kids = () => {
  return (
    <Container>
      <Wrapper>
        <Right>
          <img src={pic} alt="" />
        </Right>
        <Left>
          <h1>
            Let's Bring the Technology Out <br /> of Your Kids
          </h1>
          <h2>2 Month (Weekends Only)</h2>
          <button>ENROLL NOW</button>
        </Left>
      </Wrapper>
    </Container>
  );
};

export default Kids;

const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Wrapper = styled.div`
  width: 95%;
  height: 84%;
  background: linear-gradient(99.62deg, #1b1ed8 2.97%, #050794 94.08%);
  box-shadow: 0 10px 40px rgb(0 0 0 / 30%);
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    height: 100%;
    object-fit: contain;
  }
`;

const Left = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 2.3rem;
    color: #fff;
    font-weight: 700;
    line-height: 2rem;
    margin: 0;
  }
  h2 {
    color: #f39b28;
    letter-spacing: -2px;
  }
  button {
    width: 13vw;
    height: 7vh;
    background: linear-gradient(90deg, #fbaf1b, #b90090);
    box-shadow: 0 10.7414px 31.3291px rgb(160 121 0 / 20%);
    font-size: 1rem;
    line-height: 1.5rem;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
`;
