import React, { useState } from "react";
import styled from "styled-components";
import logo from "../Asset/logo.svg";
import { RiArrowDropDownLine } from "react-icons/ri";
import GlobalButton from "./GlobalButton";
import { HeaderDropDown } from "./HeaderDropDown";

const Header: React.FC = () => {
  const [show, setSHow] = useState<Boolean>(false);
  const [Secondshow, setSecondshow] = useState<Boolean>(false);
  const [Thirdshow, setThird] = useState<Boolean>(false);

  const toggle = () => {
    setSHow(!show);
    setSecondshow(false);
    setThird(false);
  };
  const toggle2 = () => {
    setSecondshow(!Secondshow);
    setSHow(false);
    setThird(false);
  };
  const toggle3 = () => {
    setThird(!Thirdshow);
    setSHow(false);
    setSecondshow(false);
  };
  return (
    <div>
      <Container>
        <Wrapper>
          <Logo src={logo} />
          <Navigation>
            <Nav onClick={toggle}>
              <p>Schools</p>
              <Icon>
                <RiArrowDropDownLine />
              </Icon>
            </Nav>
            <Nav onClick={toggle2}>
              <p>Enterprise </p>
              <Icon>
                <RiArrowDropDownLine />
              </Icon>
            </Nav>
            <Nav onClick={toggle3}>
              <p>Company</p>
              <Icon>
                <RiArrowDropDownLine />
              </Icon>
            </Nav>
          </Navigation>
          <Button>
            <GlobalButton
              title="Get started"
              bg="#fbaf1b"
              col="#fff"
              borCol=""
            />
            <GlobalButton title="Login" bg="" col="black" borCol="blue" />
          </Button>
        </Wrapper>

        {show ? (
          <HeaderDropDown
            header="Utiva for Individuals"
            navTitle1="Schools"
            navTitle2="Top Programs"
            navLink="data"
            navLink1="Design"
            navLink2="product"
            navLink3="cloud computing"
            navLink4="Blockchain developer"
            navLink5="programming"
            navLink6="Product Marketing"
            navLink7="Business Analysis"
            navLink8="Design Accelerator"
            navLink9="design incubator"
            navLink10="design"
            navLink11="product incubator"
            navLink12="product accelerator"
          />
        ) : null}
        {Secondshow ? (
          <HeaderDropDown
            header="
            Utiva for Enterprise"
            navTitle1="Grow with Utiva"
            navTitle2="Impact"
            navLink="Utiva Hire"
            navLink1="Utiva Impact"
            navLink2="Utiva Kids"
            navLink3="The Nucleus"
            navLink4="fddf"
            navLink5="df"
            navLink6="dfdg"
            navLink7="Blog"
            navLink8="Events"
            navLink9="Masterclass"
            navLink10="Consulting Practise"
            navLink11="Scholarship"
            navLink12="Podcast"
          />
        ) : null}
        {Thirdshow ? (
          <HeaderDropDown
            header="
Utiva as A Company"
            navTitle1="Company"
            navTitle2="Reports"
            navLink="About us"
            navLink1="data"
            navLink2="data"
            navLink3="data"
            navLink4="data"
            navLink5="data"
            navLink6="data"
            navLink7="data"
            navLink8="data"
            navLink9="data"
            navLink10="data"
            navLink11="data"
            navLink12="data"
          />
        ) : null}
      </Container>
    </div>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  height: 10vh;
  background: linear-gradient(
    114.54deg,
    rgba(247, 250, 255, 0.8) 16.01%,
    rgba(244, 244, 226, 0.8) 51.06%,
    rgba(243, 243, 251, 0.8) 89.98%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Wrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.img`
  height: 4vh;
`;
const Navigation = styled.div`
  display: flex;
  align-items: center;
  margin-left: 90px;
`;
const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-left: 40px;

  :hover {
    cursor: pointer;
  }
`;
const Icon = styled.div`
  margin-top: 10px;
  font-size: 2rem;
`;
const Button = styled.div`
  display: flex;
`;
