import React from "react";
import styled from "styled-components";
import one from "../Asset/image1.webp";
import two from "../Asset/image5.webp";
import three from "../Asset/image6.webp";
import four from "../Asset/image7.webp";

const Picture = () => {
  return (
    <Container>
      <Wrapper>
        <One src={one} />
        <Two src={two} />
        <Three src={three} />
        <Four src={four} />
      </Wrapper>
    </Container>
  );
};

export default Picture;

const Container = styled.div`
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;
const One = styled.img`
  width: 100%;
  object-fit: contain;
`;
const Two = styled.img`
  width: 100%;
  object-fit: contain;
`;
const Three = styled.img`
  width: 100%;
  object-fit: contain;
`;
const Four = styled.img`
  width: 100%;
  object-fit: contain;
`;
