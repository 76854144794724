import React from "react";
import styled from "styled-components";
import il from "../Asset/ilearning.svg";
import int from "../Asset/internship.svg";
import biz from "../Asset/business.svg";

const Process = () => {
  return (
    <Container>
      <Wrapper>
        <One>
          <p>OUR STRATEGY</p>
        </One>
        <Two>
          <h1>
            The quickest and easiest way to <br /> get quality talents
          </h1>
        </Two>
        <Three>
          <Card>
            <First>
              <img src={il} alt="" />
            </First>
            <Sec>
              <h3>Ahead of the Digital Curve</h3>
            </Sec>
            <Third>
              <p>
                Build your team to be ahead of the disruption that will change
                the way work is done.
              </p>
            </Third>
          </Card>
          <Card>
            <First>
              <img src={biz} alt="" />
            </First>
            <Sec>
              <h3>Future of Work</h3>
            </Sec>
            <Third>
              <p>
                Develop the knowledge and perspective for the future of work and
                human interaction.
              </p>
            </Third>
          </Card>
          <Card>
            <First>
              <img src={int} alt="" />
            </First>
            <Sec>
              <h3>Game Changing Approach</h3>
            </Sec>
            <Third>
              <p>
                Leverage on the best pedagogy to move from learning to
                transitioning into new roles.
              </p>
            </Third>
          </Card>
        </Three>
      </Wrapper>
    </Container>
  );
};

export default Process;

const Container = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center; ;
`;
const Wrapper = styled.div`
  height: 80%;
  width: 80%;
  display: grid;
  grid-template-rows: 0.1fr 0.1fr 0.9fr;
`;

const One = styled.div`
  font-size: 0.8rem;
`;
const Two = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  h1 {
    margin: 0;
    color: #050794;
    font-size: 1.8rem;
    line-height: 2rem;
  }
`;

const Three = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
`;
const Card = styled.div`
  width: 22vw;
  height: 20vh;
  background-color: #fff;
  border: 1px solid #ebf4ff;
  box-shadow: 0 4px 50px rgb(0 0 0 / 10%);
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
`;
const First = styled.div`
  width: 100%;
  img {
    height: 70%;
  }
`;
const Sec = styled.div`
  width: 100%;
  h3 {
    color: #050794;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;
const Third = styled.div`
  width: 100%;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    opacity: 0.8;
  }
`;
