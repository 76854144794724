import React from "react";
import styled from "styled-components";
import lady from "../Asset/lady.png";
import one from "../Asset/image7.webp";
import thre from "../Asset/image6.webp";
import four from "../Asset/image5.webp";

const Milli = () => {
  return (
    <Container>
      <Wrapper>
        <Right>
          <Image>
            <img src={lady} alt="" /> <Image1 src={one} alt="" />
            <Image2 src={thre} alt="" />
            <Image3 src={four} alt="" />
          </Image>

          <h1>1 Million</h1>
          <p>Young Africans</p>
        </Right>
        <Left>
          <h2>Our Goal</h2>
          <p>
            To enable <b>1,000,000 young Africans</b> access aspirational work
            opportunities <br /> through{" "}
            <b>market-driven premium learning programmes</b> , equipping them{" "}
            <br /> with digital skills and pre-work experience for the purpose
            of improving <br /> livelihood & reducing economic & socio-cultural
            barriers by 2030.
          </p>
        </Left>
      </Wrapper>
    </Container>
  );
};

export default Milli;

const Container = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fffa;
`;
const Wrapper = styled.div`
  height: 80%;
  width: 80%;
  display: flex;
`;
const Right = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 4.5rem;
    line-height: 1;
    font-weight: 900;
    color: #08a05c;
    margin: 0;
  }
  p {
    font-size: 2rem;
    line-height: 1.75rem;
  }
`;
const Image = styled.div`
  height: 8vh;
  margin-bottom: 15px;
  img {
    height: 100%;
    width: 10%;
    border-radius: 100px;
    border: 1.7px solid #fff;
  }
`;
const Image1 = styled.img`
  height: 100%;
  width: 25%;
  border-radius: 100px;
  border: 1.7px solid #fff;

  position: relative;
  right: 25px;
`;
const Image2 = styled.img`
  height: 100%;
  width: 25%;
  border-radius: 100px;
  border: 1.7px solid #fff;
  position: relative;
  right: 40px;
`;
const Image3 = styled.img`
  height: 100%;
  width: 25%;
  border-radius: 100px;
  border: 1.7px solid #fff;
  position: relative;
  right: 55px;
`;
const Left = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;

  h2 {
    font-size: 2.25rem;
    color: #050794;
    margin: 0;
  }
  p {
    line-height: 2.25rem;
    font-size: 1.125rem;
  }
`;
