import React from "react";
import Growth from "./ComponentTwo/Growth";
import Hero from "./ComponentTwo/Hero";
import Kids from "./ComponentTwo/Kids";
import Milli from "./ComponentTwo/Milli";
import Picture from "./ComponentTwo/Picture";
import Process from "./ComponentTwo/Process";
// import Value from "./Components/Value";
// import Hero from "./Components/Hero";
// import Numbers from "./Components/Numbers";
// import Faq from "./Components/Faq";
// import Holder from "./Components/Holder";
import Footer from "./Components/Footer";

import Header from "./Components/Header";

const App = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Picture />
      <Process />
      <Growth />
      <Kids />
      <Milli />

      {/* <Hero /> */}
      {/* <Holder /> */}
      {/* <Numbers /> */}
      {/* <Value /> */}
      {/* <Faq /> */}
      <Footer />
    </div>
  );
};

export default App;
