import React from "react";
import styled from "styled-components";
import fb from "../Asset/facebook.svg";
import act from "../Asset/act.svg";
import jica from "../Asset/jica.svg";
import hay from "../Asset/haycon.svg";
import ggf from "../Asset/ggf.svg";
import mit from "../Asset/mit.svg";
import uni from "../Asset/uni.svg";
import amz from "../Asset/amazon.svg";
import ul from "../Asset/unleash.svg";
import mic from "../Asset/microsoft.svg";

const Growth = () => {
  return (
    <Container>
      <Wrapper>
        <Top>
          <h1>Our Social Growth is Supported by our funders.</h1>
        </Top>
        <Bottom>
          <Pic>
            <img src={fb} alt="" />
          </Pic>
          <Pic>
            <img src={act} alt="" />
          </Pic>
          <Pic>
            <img src={jica} alt="" />
          </Pic>
          <Pic>
            <img src={hay} alt="" />
          </Pic>
          <Pic>
            <img src={ggf} alt="" />
          </Pic>
          <Pic>
            <img src={mit} alt="" />
          </Pic>
          <Pic>
            <img src={uni} alt="" />
          </Pic>
          <Pic>
            <img src={amz} alt="" />
          </Pic>
          <Pic>
            <img src={ul} alt="" />
          </Pic>
          <Pic>
            <img src={mic} alt="" />
          </Pic>
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default Growth;

const Container = styled.div`
  width: 100%;
  /* height: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 70%;
  display: grid;
  grid-template-rows: 0.3fr 1fr;
`;
const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;
const Bottom = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
`;
const Pic = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;

  img {
  }
`;
