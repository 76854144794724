import React from "react";
import styled from "styled-components";
import hp from "../Asset/hp.png";
import ms from "../Asset/mslogo.webp";
import vfd from "../Asset/vfd.webp";
import coro from "../Asset/coronation.webp";
import gui from "../Asset/guiness.webp";
import ekiti from "../Asset/ekitigovt.webp";
import enugu from "../Asset/enugugovt.webp";
import biz from "../Asset/business.webp";

const Hero = () => {
  return (
    <Container>
      <Wrapper>
        <Right>
          <One>
            <h1>
              You asked for a training, <br /> we give you your virtual <br />{" "}
              school!
            </h1>
          </One>
          <Two>
            <h3>
              One single platform that helps you learn tech and helps <br />{" "}
              companies hire you fast - 100X better.
            </h3>
          </Two>
          <Three>
            <button type="submit">Get Started</button>
          </Three>
          <Four>
            <LTop>
              <p>Suite delivered to</p>
            </LTop>
            <Lbott>
              <Pic>
                <img src={hp} alt="" />
              </Pic>
              <Pic>
                <img src={ms} alt="" />
              </Pic>
              <Pic>
                <img src={coro} alt="" />
              </Pic>
              <Pic>
                <img src={vfd} alt="" />
              </Pic>
              <Pic>
                <img src={gui} alt="" />
              </Pic>
              <Pic>
                <img src={ekiti} alt="" />
              </Pic>
              <Pic>
                <img src={enugu} alt="" />
              </Pic>
            </Lbott>
          </Four>
        </Right>
        <Left>
          <img src={biz} alt="" />
        </Left>
      </Wrapper>
    </Container>
  );
};

export default Hero;

const Container = styled.div`
  width: 100%;
  height: 90vh;
  background: linear-gradient(
    114.54deg,
    rgba(247, 250, 255, 0.8) 16.01%,
    rgba(244, 244, 226, 0.8) 51.06%,
    rgba(243, 243, 251, 0.8) 89.98%
  );
  display: flex;
  justify-content: center;
  align-items: center; ;
`;
const Wrapper = styled.div`
  width: 80%;
  display: flex;
`;
const Right = styled.div`
  width: 50%;
`;

const One = styled.div`
  width: 100%;

  h1 {
    color: #050794;
    font-size: 2.5rem;
    margin: 0;
    line-height: 3rem;
  }
`;

const Two = styled.div`
  width: 100%;

  h3 {
    margin: 0;
    margin-top: 30px;
    font-weight: 400;
    font-size: 1rem;
  }
`;
const Three = styled.div`
  width: 100%;

  button {
    padding: 18px 30px;
    background-color: orange;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: all ease-in 0.2s;

    :hover {
    }
  }
`;
const Four = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const LTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    opacity: 0.8;
    font-size: 0.8rem;
  }
`;
const Lbott = styled.div`
  height: 20vh;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
`;
const Pic = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 60%;
    width: 70%;
    object-fit: contain;
  }
`;
const Left = styled.div`
  width: 50%;
  display: flex;
  justify-content: left;

  img {
    height: 70vh;
  }
`;
